<script>
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartdata: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            backgroundColor: '#f44336',
            lastHoveredIndex: null, // 直前にホバーしていたバーのインデックス
        };
    },
    computed: {
        shapedData() {
            const max_freq = Math.max.apply(null,
                this.chartdata.map(function (o) { return o.student_frequency; })
            );
            // グラフの横軸 0~最大頻度の配列
            const labels = [...Array(max_freq + 1).keys()];
            // グラフの値を格納する配列
            const data = Array(max_freq + 1);
            data.fill(0);

            this.chartdata.forEach(element => {
                data[element.student_frequency]++;
            });

            return {
                'labels': labels,
                'datasets': [
                    {
                        'backgroundColor': this.backgroundColor,
                        'label': '人数',
                        'data': data
                    },
                ],

            };
        },
        color() {
            return this.$vuetify.theme.isDark ? '#FFFFFF' : '#111111';
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            fontColor: this.color,
                            labelString: ['検出枚数'],
                        },
                        ticks: {
                            fontColor: this.color,
                        },
                        gridLines: {
                            display: false,
                            borderDash: [2, 1],
                            color: this.color,
                        },
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            fontColor: this.color,
                            labelString: '人数',
                        },
                        ticks: {
                            beginAtZero: true,
                            stepSize: 10,
                            fontColor: this.color,
                        },
                        gridLines: {
                            color: this.color,
                            zeroLineColor: this.color,
                            borderDash: [2, 1],
                        },
                    }]
                },
                legend: {
                    display: false
                },
                hover: {
                    onHover: (event, elements) => {
                        if (elements.length > 0) {
                            const hoveredIndex = elements[0]._index;

                            // 直前と同じバーなら何もしない
                            if (this.lastHoveredIndex == hoveredIndex) return;

                            // ホバー中のバーのインデックスを更新
                            this.lastHoveredIndex = hoveredIndex;
                            event.target.style.cursor = 'pointer';

                            this.graphBarHovered(event, elements);
                        } else {
                            this.lastHoveredIndex = null; // ホバーが外れたらリセット
                            event.target.style.cursor = 'default';
                        }
                    }
                },
                onClick: this.graphBarClicked
            };
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    },
    methods: {
        graphBarHovered(e, el) {
            if (!el || el.length === 0) return;
            this.$emit('graphBarHovered', el[0]._model.label);
        },
        graphBarClicked(e, el) {
            if (!el || el.length === 0) return;
            this.$emit('graphBarClicked', el[0]._model.label);
        }
    },
};
</script>

