<template>
    <page-container>
        <template slot="navigation" />
        <template slot="header">
            <!-- 撮影行事ヘッダ -->
            <v-toolbar fixed dense :color="$root.getTheme().tertiary">
                <template>
                    <v-container fluid class="ma-0 pa-0">
                        <v-row justify="space-between">
                            <v-col cols="3" class="shooting-header">
                                <v-toolbar-title v-if="storageData.isInitialized" class="no-underline-breadcrumbs"
                                    style="display: flex; align-items: center;">
                                    <a :style="{ fontSize: '14px', textDecoration: 'underline' }"
                                        @click="transitToShootings">
                                        撮影行事
                                    </a>
                                    <span>
                                        <v-icon small>keyboard_arrow_right</v-icon>
                                        {{ storageData.shooting.name }}
                                    </span>
                                </v-toolbar-title>
                            </v-col>
                            <v-col cols="9" class="ma-0 py-0 pl-0 pr-6 shooting-header"
                                style="display: flex; align-items: center;">
                                <v-row justify="start">
                                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-right: 24px;"
                                        class="hover-pointer icon-medium"
                                        @click="() => { storageData.pageSpreadMemoDialog = true; }">
                                        <img style="height: 16px; width: 16px;" src="/images/ep_memo.png" />
                                        <span style="font-size: 7px;">メモ</span>
                                    </div>
                                    <v-divider vertical color="#999999" class="my-1"
                                        style="height: 40px; min-height: 0 !important;" />
                                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin: 0 8px;"
                                        class="hover-pointer icon-medium" @click="openPhotoUploadDialog">
                                        <img style="height: 21px; width: 21px;" src="/images/ic_baseline-upload.png" />
                                        <span style="font-size: 7px;">アップロード</span>
                                    </div>
                                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin: 0 8px;"
                                        class="hover-pointer icon-medium" @click="doAiProc">
                                        <img style="height: 20px; width: 20px;" src="/images/material-symbols_face.png" />
                                        <span style="font-size: 7px;">顔認証</span>
                                    </div>
                                </v-row>
                                <v-row justify="end">
                                    <icon-with-text text="削除" :show-text="showText" icon icon-name="mdi-delete"
                                        :disabled="!isActivePhotoSelected" @click="deletePhotos" style="font-size: 7px;" />
                                    <v-divider vertical color="#999999" class="my-1"
                                        style="height: 40px; min-height: 0 !important;" />
                                    <div style="display: flex; justify-content: center; align-items: center; margin-left: 8px;"
                                        class="hover-pointer icon-medium" @click="openAutoSelectDialog">
                                        <img style="height: 27px; width: 50px;" src="/images/photo-ai.png" />
                                    </div>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-toolbar>
        </template>

        <template slot="top" />

        <template slot="middle">
            <v-container fluid class="pa-0">
                <!-- 写真未存在時のナビゲーション -->
                <v-row class="ma-0 pa-0">
                    <!-- 写真サムネイル一覧部 -->
                    <v-col :cols="suggestingSwapCandidate ? 5 : 12" class="ma-0 pa-0">
                        <v-container fluid class="pt-0" style="height: auto;">
                            <v-tabs v-model="selectedTab" style="height: 60px !important;">
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="auto" style="padding: 0;">
                                        <v-row class="ma-0 pa-0" justify="space-between"
                                            style="height: 100%; width: 388px;">
                                            <v-col cols="6"
                                                style="display: flex; align-items: center; justify-content: center; padding: 0;">
                                                <v-tab style="height: 100%; width: 100%;"
                                                    :style="{ color: selectedTab === 1 ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 1)' }">{{
                                                        "全写真(" + photos.length + ")" }}</v-tab>
                                            </v-col>
                                            <v-col cols="6"
                                                style="display: flex; align-items: center; justify-content: center; padding: 0;">
                                                <v-tab style="color: #FF8B00; height: 100%; width: 100%;"
                                                    :style="{ color: selectedTab === 1 ? 'rgba(255, 139, 0, 1)' : 'rgba(255, 139, 0, 0.6)' }">{{
                                                        "選定(" +
                                                        photosSelectedForPageSpread.length
                                                        + ")" }}</v-tab>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="auto"
                                        style="display: flex; justify-content: space-between; padding-left: 28px; padding-right: 0; min-width: 802px; flex-grow: 1;">
                                        <div class="d-flex align-center"
                                            style="border: 1px solid #666; border-radius: 4px; height: 40px;">
                                            <span style="padding: 8px; font-size: 6px;">
                                                表示<br>操作
                                            </span>
                                            <v-divider vertical color="#999999" />
                                            <div style="display: flex; align-items: center; margin-left: 12px;">
                                                <img style="height: 14px; width: 14px; margin: 4px;"
                                                    src="/images/filter.png">
                                                <v-select v-model="storageData.label" :items="labels"
                                                    :label="storageData.label ? '' : '数字ラベル'" item-text="no" clearable
                                                    return-object dense outlined hide-details @change="searchPhotos"
                                                    class="custom-select-label">
                                                    <template #item="{ item }">
                                                        <v-icon v-if="!!item.label_icon" :color="'#' + item.color_code">
                                                            {{ item.label_icon }}
                                                        </v-icon>
                                                        <img v-else style="height: 14px; width: 51px;"
                                                            src="/images/delete-label.png" />
                                                    </template>
                                                    <template #selection="{ item }">
                                                        <v-icon v-if="!!item.label_icon" :color="'#' + item.color_code">
                                                            {{ item.label_icon }}
                                                        </v-icon>
                                                        <div v-else
                                                            style="height: 100%; display: flex; align-items: center;">
                                                            <img style="height: 14px; width: 51px;"
                                                                src="/images/delete-label.png" />
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </div>

                                            <div style="display: flex; align-items: center; margin-left: 8px;">
                                                <img style="height: 22px; width: 22px; margin: 4px;"
                                                    src="/images/bx_sort-down.png">
                                                <v-select v-model="storageData.sortItem" :items="sortItems"
                                                    :label="storageData.sortItem ? '' : '並び順'" return-object dense outlined
                                                    hide-details @change="searchPhotos" class="custom-select" />
                                            </div>

                                            <v-btn-toggle v-model="withRectangleToggles" dense group multiple>
                                                <icon-with-text
                                                    style="display: flex; justify-content: center; align-items: center; padding-top: 4px;"
                                                    :color="withRectangleButtonColor" :show-text="showText" :value="2" icon
                                                    icon-name="mdi-face-recognition" @click="switchWithRectangleMode" />
                                                <div style="display: flex; justify-content: center; align-items: center;"
                                                    class="hover-pointer"
                                                    :disabled="thumbnailSize.id >= thumbnailSizes.length"
                                                    @click="changeThumbnailSize(1)">
                                                    <img style="height: 21px; width: 23px;" src="/images/enlarge.png" />
                                                </div>
                                                <div style="display: flex; justify-content: center; align-items: center; margin: 0 24px 0 8px;"
                                                    class="hover-pointer" :disabled="thumbnailSize.id <= 1"
                                                    @click="changeThumbnailSize(-1)">
                                                    <img style="height: 21px; width: 23px;" src="/images/reduce.png" />
                                                </div>
                                            </v-btn-toggle>
                                        </div>
                                        <div
                                            style="border: 1px solid #666; border-radius: 4px; max-height: 39px; display: flex; margin-right: 8px; align-items: center; justify-content: flex-end;">
                                            <span
                                                style="font-size: 6px; color: #FFF; background-color: #666666; padding: 8px; border-radius: 0; height: 100%; display: flex; align-items: center;">行事<br>出力<br>操作</span>
                                            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin: 0 12px;"
                                                class="hover-pointer icon-medium" :disabled="!isPhotoExisting"
                                                @click="openShootingFrequencyDialog">
                                                <img style="height: 24px; width: 24px;"
                                                    src="/images/bitcoin-icons_graph-filled.png" />
                                                <span style="font-size: 7px;">人物頻度</span>
                                            </div>
                                            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin: 0 12px; height: 100%;"
                                                class="hover-pointer icon-medium" :disabled="isDownloadButtonClickable"
                                                @click="downloadPhotos">
                                                <img style="height: 24px; width: 24px;"
                                                    src="/images/ic_baseline-download.png" />
                                                <span style="font-size: 7px;">ダウンロード</span>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-tab-item class="pb-2 px-7">
                                    <v-row class="ma-0 pa-0 pb-2" dense
                                        style="display: flex; flex-wrap: nowrap; overflow-x: auto; overflow-y: hidden;"
                                        :style="{ maxHeight: '90vh' }">
                                        <!-- 人物頻度表示部 -->
                                        <v-col cols="auto" class="ma-0 pa-0" style="width: 360px;">
                                            <v-card :loading="frequencyDisplayLoading" style="padding: 12px;">
                                                <div style="display: flex; justify-content: space-between; margin: 8px 0;">
                                                    <img style="height: 15px; width: 15px;" class="hover-pointer"
                                                        @click="openFaceSearchDialog"
                                                        src="/images/mingcute_search-line.png" />
                                                    <span style="padding-right: 8px;">{{ shootingFaces.length + "件"
                                                    }}</span>
                                                </div>
                                                <div v-if="storageData.faceSearchDialog"
                                                    style="width: 100%; border: #666666 solid 1px; border-radius: 4px;">
                                                    <div style="background-color: #0F68B1; padding: 8px;">
                                                        <span style="color: #FFF;">絞り込み条件</span>
                                                    </div>
                                                    <v-text-field v-model="storageData.personStudentNo" label="出席番号" dense
                                                        outlined hide-details style="margin: 16px; width: 50%;" />
                                                    <v-text-field v-model="storageData.personNote" label="個人メモ" dense
                                                        outlined hide-details style="margin: 16px;" />
                                                    <v-row justify="space-between" class="ma-4">
                                                        <v-btn class="search-button"
                                                            style="background-color: #FFFFFF; border: 1px solid #666666;"
                                                            @click="() => {
                                                                storageData.faceSearchDialog = false;
                                                            }">
                                                            キャンセル
                                                        </v-btn>
                                                        <v-btn class="search-button"
                                                            style="background-color: #0F68B1; color: #FFF;"
                                                            @click="searchFrequencies">
                                                            絞り込み
                                                        </v-btn>
                                                    </v-row>
                                                </div>
                                                <v-card-text v-if="frequencies.length == 0" class="px-1">
                                                    <v-icon>error</v-icon>
                                                    未検出
                                                </v-card-text>
                                                <v-card-text v-else class="py-1 px-0 overlay-scrollable"
                                                    :style="{ maxHeight: storageData.faceSearchDialog ? '50vh' : '80vh' }">
                                                    <v-container class="ma-0 pa-0">
                                                        <v-row v-if="frequencies.length >= 1" class="ma-0 pa-0">
                                                            <v-col v-for=" f  in  frequencies " :key="f.face_id"
                                                                :cols="frequencyDisplayColumnNum" class="ma-0 pa-0">
                                                                <person-frequency-card :frequency="f"
                                                                    :is-unselected-face="storageData.selectedFaces.length != 0 && !storageData.selectedFaces.includes(f.face_id)"
                                                                    :click-callback="clickFaceInBestFrequencies" />
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="auto" style="padding: 0 0 0 28px; min-width: 802px; flex-grow: 1;">
                                            <div
                                                style="padding-right: 8px; display: flex; justify-content: space-between; align-items: center;">
                                                <div>
                                                    <v-checkbox :label="activeShouldSelectAll ? '全て選択' : '全選択解除'"
                                                        @change="selectAllPhotos(activeShouldSelectAll)" />
                                                </div>
                                                <div>
                                                    {{ photos.length + " 枚" }}
                                                </div>
                                            </div>
                                            <div v-if="!isPhotoExisting" style="text-align: center; font-size: 16px;">
                                                行事写真をアップロードしてください
                                            </div>
                                            <div v-else-if="photos.length === 0"
                                                style="text-align: center; font-size: 16px;">
                                                選択された顔が写っている行事写真がありません。
                                            </div>
                                            <v-row v-else class="ma-0 pa-0 overlay-scrollable"
                                                style="height: 80vh; display: grid;"
                                                :style="{ gridTemplateColumns: `repeat(auto-fill, minmax(${this.thumbnailSize.width + 16}px, 1fr))` }">
                                                <v-col v-for=" photo  in  photos " :key="photo.id"
                                                    style="display: flex; justify-content: center; padding: 12px 0;">
                                                    <photo-thumbnail-card :photo="photo" :labels="labels"
                                                        :size="thumbnailSize" :with-rectangle="storageData.withRectangle"
                                                        :click-callback="openPhotoDialog"
                                                        :is-selected="photo.uiStatus.selected" @photo-selected="(value) => {
                                                            photo.uiStatus.selected = value;
                                                        }
                                                            " @selection-status-changed-for-page-spread="(value) => {
        updateSelectionStatus(value, photo);
    }
        " @auto-selection-status-changed-for-page-spread="(value) => {
        updateAutoSelectionStatus(value, photo);
    }" @label-changed="incrementPhotoLabel" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item class="pb-2 px-7">
                                    <v-row class="ma-0 pa-0 pb-2" dense
                                        style="display: flex; flex-wrap: nowrap; overflow-x: auto; overflow-y: hidden;"
                                        :style="{ maxHeight: '90vh' }">
                                        <!-- 人物頻度表示部 -->
                                        <v-col cols="auto" class="ma-0 pa-0" style="width: 360px;">
                                            <v-card :loading="frequencyDisplayLoading" style="padding: 12px;">
                                                <div style="display: flex; justify-content: space-between; margin: 8px 0;">
                                                    <img style="height: 15px; width: 15px;" class="hover-pointer"
                                                        @click="openFaceSearchDialog"
                                                        src="/images/mingcute_search-line.png" />
                                                    <span style="padding-right: 8px;">{{ shootingFaces.length + "件"
                                                    }}</span>
                                                </div>
                                                <div v-if="storageData.faceSearchDialog"
                                                    style="width: 100%; border: #666666 solid 1px; border-radius: 4px;">
                                                    <div style="background-color: #0F68B1; padding: 8px;">
                                                        <span style="color: #FFF;">絞り込み条件</span>
                                                    </div>
                                                    <v-text-field v-model="storageData.personStudentNo" label="出席番号" dense
                                                        outlined hide-details style="margin: 16px; width: 50%;" />
                                                    <v-text-field v-model="storageData.personNote" label="個人メモ" dense
                                                        outlined hide-details style="margin: 16px;" />
                                                    <v-row justify="space-between" class="ma-4">
                                                        <v-btn class="search-button"
                                                            style="background-color: #FFFFFF; border: 1px solid #666666;"
                                                            @click="() => {
                                                                storageData.faceSearchDialog = false;
                                                            }">
                                                            キャンセル
                                                        </v-btn>
                                                        <v-btn class="search-button"
                                                            style="background-color: #0F68B1; color: #FFF;"
                                                            @click="searchFrequencies">
                                                            絞り込み
                                                        </v-btn>
                                                    </v-row>
                                                </div>
                                                <v-card-text v-if="frequencies.length == 0" class="px-1">
                                                    <v-icon>error</v-icon>
                                                    未検出
                                                </v-card-text>
                                                <v-card-text v-else class="py-1 px-0 overlay-scrollable"
                                                    :style="{ maxHeight: '80vh' }">
                                                    <v-container class="ma-0 pa-0">
                                                        <v-row v-if="frequencies.length >= 1" class="ma-0 pa-0">
                                                            <v-col v-for=" f  in  frequencies " :key="f.face_id"
                                                                :cols="frequencyDisplayColumnNum" class="ma-0 pa-0">
                                                                <person-frequency-card :frequency="f"
                                                                    :is-unselected-face="storageData.selectedFaces.length != 0 && !storageData.selectedFaces.includes(f.face_id)"
                                                                    :click-callback="clickFaceInBestFrequencies" />
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="auto" style="padding: 0 0 0 28px; min-width: 802px; flex-grow: 1;">
                                            <div
                                                style="padding-right: 8px; display: flex; justify-content: space-between; align-items: center;">
                                                <div>
                                                    <v-checkbox :label="activeShouldSelectAll ? '全て選択' : '全選択解除'"
                                                        @change="selectAllPhotos(activeShouldSelectAll)" />
                                                </div>
                                                <div>
                                                    {{ photosSelectedForPageSpread.length + "枚" }}
                                                </div>
                                            </div>
                                            <div v-if="!isPhotoExisting" style="text-align: center; font-size: 16px;">
                                                行事写真をアップロードしてください
                                            </div>
                                            <div v-else-if="photosSelectedForPageSpread.length === 0"
                                                style="text-align: center; font-size: 16px;">
                                                選択された顔が写っている行事写真がありません。
                                            </div>
                                            <v-row v-else class="ma-0 pa-0 overlay-scrollable"
                                                style="height: 80vh; display: grid;"
                                                :style="{ gridTemplateColumns: `repeat(auto-fill, minmax(${this.thumbnailSize.width + 16}px, 1fr))` }">
                                                <v-col v-for=" photo  in  photosSelectedForPageSpread " :key="photo.id"
                                                    style="display: flex; justify-content: center; padding: 12px 0;">
                                                    <photo-thumbnail-card :photo="photo" :labels="labels"
                                                        :size="thumbnailSize" :with-rectangle="storageData.withRectangle"
                                                        :click-callback="openPhotoDialog"
                                                        :is-selected="photo.uiStatus.selected" @photo-selected="(value) => {
                                                            photo.uiStatus.selected = value;
                                                        }
                                                            " @selection-status-changed-for-page-spread="(value) => {
        updateSelectionStatus(value, photo);
    }
        " @auto-selection-status-changed-for-page-spread="(value) => {
        updateAutoSelectionStatus(value, photo);
    }" @label-changed="incrementPhotoLabel" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>
                        </v-container>
                    </v-col>

                    <!-- 検出人物表示部 -->
                </v-row>
            </v-container>
        </template>

        <template slot="bottom">
            <!-- 写真詳細ダイアログ -->
            <photo-dialog ref="photoDialogVue" :album-id="albumId" :photo-id="storageData.photoId" :photos="activePhotos"
                :faces="faces" :labels="labels" :tags="tags" :page-spread-mode="false" :visible="storageData.photoDialog"
                :slide-photo-callback="applyChangePhotoId" :update-photo-callback="applyUpdatePhoto"
                :close-callback="closePhotoDialog" />
            <!-- 人物頻度確認ダイアログ -->
            <v-dialog v-model="storageData.shootingFrequencyDialog" persistent fullscreen hide-overlay
                transition="dialog-bottom-transition">
                <v-card :color="$root.getTheme().background">
                    <shooting-frequency ref="shootingFrequencyVue" :album-id="albumId"
                        :close-callback="closeShootingFrequencyDialog" @selectedFrequentFaces="selectedFrequentFaces" />
                </v-card>
            </v-dialog>
            <!-- 写真アップロードダイアログ -->
            <photo-upload-dialog ref="photoUploadDialogVue" :album-id="albumId" :shooting-id="shootingId"
                :photos-length="photos.length" :visible="storageData.photoUploadDialog"
                :close-callback="closePhotoUploadDialog" :existing-photo-names="photos.map((p) => {
                    return p.name;
                })
                    " @done-upload="searchPhotos" />
            <!-- 自動選定設定入力ダイアログ -->
            <auto-select-dialog ref="autoSelectDialogVue" :album-id="albumId" :shooting-id="shootingId" :labels="labels"
                :completed-callback="autoSelectCompleted" />
            <!-- 自動選定結果出力ダイアログ -->
            <v-dialog v-model="storageData.autoSelectedPhotosDialog" persistent fullscreen hide-overlay
                transition="dialog-bottom-transition">
                <v-card :color="$root.getTheme().background">
                    <auto-selected-photos ref="autoSelectPhotosVue" :album-id="albumId" :shooting-id="shootingId"
                        :auto-select-result="storageData.autoSelectResult" :close-callback="closeAutoSelectedPhotosDialog"
                        @selection-status-changed="(val) => {
                            undoMoveStack.push(val);
                        }
                            " />
                </v-card>
            </v-dialog>
            <!-- ページメモダイアログ -->
            <v-dialog v-model="storageData.pageSpreadMemoDialog" persistent max-width="400">
                <v-form ref="pageSpreadForm" lazy-validation>
                    <v-card class="pa-3">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-textarea v-model="storageData.shooting.note" label="メモ" outlined hide-details />
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container class="px-3">
                            <v-row justify="space-between">
                                <cancel-dialog-button width="100" @click="() => {
                                    storageData.pageSpreadMemoDialog = false;
                                }
                                    ">
                                    キャンセル
                                </cancel-dialog-button>
                                <proceed-dialog-button width="100" @click="registerPageSpreadMemo">
                                    登録
                                </proceed-dialog-button>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-form>
            </v-dialog>
            <!-- 人物頻度変化確認ダイアログ -->
            <swap-confirm-dialog v-model="swapConfirmDialog" :added-people="addedPeople" :rejected-people="rejectedPeople"
                :executable="areBothSwapCandidateSelected" @executed="swapPhotos" />
        </template>
    </page-container>
</template>

<script>
import event from '../../utils/event';
import http from '../../services/http';
import urlUtil from '../../utils/url';
import leaveConform from '../../mixins/leave-confirm-mixin';
import monet from 'monet';
import { mdiArrowULeftBottomBold } from '@mdi/js';
import { awaitJobDone } from '../../services/awaitJobDone';

export default {
    components: {
        'photo-thumbnail-card': require('../Photos/PhotoThumbnailCard.vue').default,
        'person-frequency-card': require('../Frequency/PersonFrequencyCard.vue')
            .default,
        'photo-dialog': require('../Photos/PhotoDialog.vue').default,
        'shooting-frequency': require('../Frequency/ShootingFrequency.vue').default,
        'face-select-panel': require('../Faces/FaceSelectPanel.vue').default,
        'photo-upload-dialog': require('../Dialogs/PhotoUploadDialog.vue').default,
        'icon-with-text': require('../Icons/IconWithText.vue').default,
        'auto-select-dialog': require('../PageSpreads/AutoSelectDialog.vue')
            .default,
        'auto-selected-photos': require('../PageSpreads/AutoSelectedPhotos.vue')
            .default,
        'swap-confirm-dialog': require('./SwapConfirmDialog.vue').default,
        'icon-sex-ratio': require('../Icons/IconSexRatio.vue').default,
    },
    mixins: [leaveConform],
    props: {
        albumId: {
            type: [String, Number],
            default: 0,
        },
        shootingId: {
            type: [String, Number],
            default: 0,
        },
    },
    data() {
        return {
            viewID: '',
            drawer: false,
            storageData: {
                isInitialized: false,
                shooting: undefined,

                photoDialog: false, // 写真詳細ダイアログの表示状態
                photoId: 0, // 詳細表示中の写真ID

                label: undefined, // 検索条件
                selectedTags: [], // 検索条件
                selectedFaces: [], // 検索条件
                pageNo: '', // 検索条件
                sortItem: undefined, // ソート条件
                sortOrder: 'asc', // ソート条件
                thumbnailSize: undefined, // サムネイルサイズ
                withRectangle: true, // 検出顔矩形付きサムネイルを表示するか否か

                shootingFrequencyDialog: false, // 人物頻度確認ダイアログの表示フラグ
                pageSpreadMemoDialog: false, // ページ構成メモダイアログ
                autoSelectedPhotosDialog: false, // 自動選定結果出力ダイアログ
                photoUploadDialog: false,
                faceSearchDialog: false, // 顔マスタの操作ダイアログ

                personStudentNo: '', // 人物検索条件
                personNote: '', // 人物検索条件
                frequencySortItem: undefined, // 人物頻度ソート条件
            },
            rules: {},
            httpState: http.state,
            photos: [],
            allPhotos: [],
            shootingFaces: [],
            pageSpreads: [],
            faces: [],
            tags: [],
            labels: [],
            frequencies: [],
            bestFrequencies: [],
            worstFrequencies: [],
            swapCandidatePhotoIds: [],
            shouldSelectAll: true,
            shouldSelectAllInPageSpread: true,
            shouldSelectAllInSwapSuggestion: true,
            isDownloading: false,
            sortItems: [
                { value: 'shooting_time', text: '撮影日時' },
                { value: 'name', text: '写真名' },
                { value: 'photo_face_count', text: '検出人数' },
            ],
            thumbnailSizesBase: [
                {
                    id: 1,
                    width: 240,
                    height: 160,
                    cols: 2,
                    sm: 12,
                    md: 4,
                    lg: 3,
                    xl: 2,
                },
                {
                    id: 2,
                    width: 300,
                    height: 200,
                    cols: 3,
                    sm: 12,
                    md: 5,
                    lg: 4,
                    xl: 3,
                },
                {
                    id: 3,
                    width: 600,
                    height: 400,
                    cols: 6,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 6,
                },
                {
                    id: 4,
                    width: 720,
                    height: 480,
                    cols: 6,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 6,
                },
            ],
            thumbnailSizeIndex: 0,
            frequencySortItems: [
                { value: 1, text: '撮影行事内頻度' },
                { value: 2, text: 'アルバム内頻度' },
            ],
            withRectangleToggles: [2],
            showText: true,
            icons: {
                mdiArrowULeftBottomBold,
            },
            undoMoveStack: [], // // 選定操作取消写真ID,変更状態のスタック（後入先出し）
            selectedTab: null,
            frequencyDisplayLoading: false,
            frequencyDisplayColumnNum: 3, // 人物頻度表示列数
            frequencyDisplayRowNum: 5, // 人物頻度表示行数
            swapCandidateCount: 5, // 入れ替え候補表示数
            swapConfirmDialog: false, // 入れ替え結果確認ダイアログを表示するか
            swapCandidateLoading: false,
            descriptions: {
                noGoodSwapCandidateExistingDescription:
                    ' 条件に該当する写真が存在しません。',
                noFrequencyDisplayFaceSelectedDescription:
                    '画面右列の人物頻度から顔を選択してください。',
            },
        };
    },
    computed: {
        isActivePhotoSelected() {
            // const allPhotoTabSelected = this.allPhotoTabSelected;
            // return this.activePhotos.some(photo => allPhotoTabSelected ? photo.uiStatus.selected : photo.uiStatus.selectedInPageSpread);
            return this.activePhotos.some((photo) => photo.selected);
        },
        isSelectedSwapSuggestionPhoto() {
            return this.swapCandidatePhotos.some(
                (p) => p.uiStatus.selectedInSwapCandidateSuggestion
            );
        },
        isSelectedPageSpreadPhoto() {
            return this.photosSelectedForPageSpread.some(
                (p) => p.uiStatus.selectedInPageSpread
            );
        },
        areBothSwapCandidateSelected() {
            return (
                this.isSelectedPageSpreadPhoto && this.isSelectedSwapSuggestionPhoto
            );
        },
        isPhotoExisting() {
            return !(
                !this.storageData.label &&
                !this.storageData.pageNo &&
                !this.storageData.selectedTags.length &&
                !this.storageData.selectedFaces.length &&
                this.storageData.isInitialized &&
                !this.photos.length
            );
        },
        isFaceMasterCreated() {
            return this.faces.length > 0;
        },
        isUndoMoveStacked() {
            return this.undoMoveStack.length > 0;
        },
        withRectangleButtonColor() {
            return this.storageData.withRectangle ? 'accent' : '';
        },
        photosSelectedForPageSpread() {
            return this.photos.filter((photo) => {
                return photo.is_selected_for_page_spread || photo.is_auto_selected_for_page_spread;
            });
        },
        allPhotoTabSelected() {
            return this.selectedTab == 0;
        },
        activePhotos() {
            return this.allPhotoTabSelected
                ? this.photos.map((p) => {
                    p.selected = p.uiStatus.selected;
                    return p;
                })
                : this.photosSelectedForPageSpread
                    .map((p) => {
                        p.selected = p.uiStatus.selectedInPageSpread;
                        return p;
                    })
                    .concat(
                        this.swapCandidatePhotos.map((p) => {
                            p.selected = p.uiStatus.selectedInSwapCandidateSuggestion;
                            return p;
                        })
                    );
        },
        activeShouldSelectAll: {
            get: function () {
                return this.allPhotoTabSelected
                    ? this.shouldSelectAll
                    : this.shouldSelectAllInPageSpread;
            },
            set: function (value) {
                if (this.allPhotoTabSelected) {
                    this.shouldSelectAll = value;
                } else {
                    this.shouldSelectAllInPageSpread = value;
                }
            },
        },
        swapCandidatePhotos() {
            return this.suggestingSwapCandidate
                ? this.photos.filter((photo) => {
                    return this.swapCandidatePhotoIds.includes(photo.id);
                })
                : [];
        },
        isFrequencyDisplayFaceHighlighted() {
            return (
                this.bestFrequencies.some((f) => {
                    return f.is_highlighting;
                }) |
                this.worstFrequencies.some((f) => {
                    return f.is_highlighting;
                })
            );
        },
        frequencyDisplayDescription() {
            return this.isFrequencyDisplayFaceHighlighted
                ? // 顔選択はされているが該当する写真が無い場合
                this.descriptions.noGoodSwapCandidateExistingDescription
                : this.descriptions.noFrequencyDisplayFaceSelectedDescription;
        },
        frequencyDisplayCount() {
            return (
                this.frequencyDisplayRowNum * (12 / this.frequencyDisplayColumnNum)
            );
        },
        addedPeople() {
            return this.aggregateFrequency(
                this.swapCandidatePhotos.filter(
                    (p) => p.uiStatus.selectedInSwapCandidateSuggestion
                )
            );
        },
        rejectedPeople() {
            return this.aggregateFrequency(
                this.photosSelectedForPageSpread.filter(
                    (p) => p.uiStatus.selectedInPageSpread
                )
            );
        },
        thumbnailSizes() {
            return !this.suggestingSwapCandidate
                ? this.thumbnailSizesBase
                : [
                    {
                        id: 1,
                        width: 240,
                        height: 160,
                        cols: 4,
                        sm: 12,
                        md: 6,
                        lg: 6,
                        xl: 4,
                    },
                    {
                        id: 2,
                        width: 300,
                        height: 200,
                        cols: 6,
                        sm: 12,
                        md: 12,
                        lg: 6,
                        xl: 6,
                    },
                    {
                        id: 3,
                        width: 600,
                        height: 400,
                        cols: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                ];
        },
        thumbnailSize() {
            return this.thumbnailSizeIndex >= this.thumbnailSizes.length
                ? this.thumbnailSizes[this.thumbnailSizes.length - 1]
                : this.thumbnailSizeIndex < 0
                    ? this.thumbnailSizes[0]
                    : this.thumbnailSizes[this.thumbnailSizeIndex];
        },
        sexRatio() {
            if (!this.storageData.shooting.sex_ratio) {
                return false;
            }
            if (this.allPhotoTabSelected) {
                return {
                    manRatio: Math.round(this.storageData.shooting.sex_ratio * 100) + '%',
                    womanRatio:
                        Math.round((1 - this.storageData.shooting.sex_ratio) * 100) + '%',
                };
            } else {
                // フロントで集計 微妙すぎる
                const count = this.photosSelectedForPageSpread.reduce(
                    (accumulator, current) => {
                        // man count
                        accumulator[0] += current.photo_faces.filter((pf) => {
                            return pf.face.sex == '男性';
                        }).length;
                        // whole count
                        accumulator[1] += current.photo_faces.length;
                        return accumulator;
                    },
                    [0, 0]
                );

                return count[1] == 0
                    ? { manRatio: '0%', womanRatio: '0%' }
                    : {
                        manRatio: Math.round((count[0] / count[1]) * 100) + '%',
                        womanRatio: Math.round(100 - (count[0] / count[1]) * 100) + '%',
                    };
            }
        },
        photographerName() {
            return this.storageData.shooting.photographer_name
                ? this.storageData.shooting.photographer_name
                : '未登録';
        },
        isDownloadButtonClickable() {
            return Boolean(!this.isPhotoExisting | this.isDownloading);
        },
    },
    watch: {
        selectedTab: function () {
            this.searchFrequencies();
        },
    },
    created() {
        this.storageData.sortItem = this.sortItems[0];
        this.storageData.frequencySortItem = this.frequencySortItems[0];

        event.on('saveStorage', () => {
            if (this.storageData.isInitialized == true) {
                this.saveStorageData();
            }
        });
        event.on('updatedFaces', () => {
            this.fetchFaces();
        });
        event.on('updatedTags', () => {
            this.fetchTags();
        });
    },
    mounted() {
        this.restoreStorageData();
        this.initialize();
    },
    beforeDestroy() {
        event.off('saveStorage');
        event.off('updatedFaces');
        event.off('updatedTags');
    },
    methods: {
        initialize() {
            this.storageData.isInitialized = false;
            this.undoMoveStack = [];
            Promise.all([
                this.fetchAlbum(),
                this.fetchShooting(),
                // this.fetchImportedPageSpreads(),
                this.fetchFaces(),
                this.fetchTags(),
                this.fetchLabels(),
            ])
                .then(() => {
                    return this.searchPhotos();
                })
                .then(() => {
                    this.storageData.isInitialized = true;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError('初期化処理に失敗しました。', '初期化エラー', error);
                });
        },
        fetchAlbum() {
            return http.get('albums/' + this.albumId).then((res) => {
                this.album = res.data;
            });
        },
        fetchShooting() {
            return http
                .get('shootings/' + this.shootingId)
                .then((res) => (this.storageData.shooting = res.data));
        },
        fetchImportedPageSpreads() {
            return http
                .get(
                    'page_spreads?album_id=' +
                    this.albumId +
                    '&photo_directory_status=取込済'
                )
                .then((res) => (this.pageSpreads = res.data));
        },
        fetchFaces() {
            return http
                .get('faces?album_id=' + this.albumId)
                .then((res) => (this.faces = res.data));
        },
        fetchTags() {
            return http
                .get('tags?album_id=' + this.albumId)
                .then((res) => (this.tags = res.data));
        },
        fetchLabels() {
            return http.get('labels').then(
                (res) =>
                (this.labels = res.data.map((l) => {
                    l.label_icon =
                        l.no >= 0 && l.no <= 9 ? 'mdi-numeric-' + l.no + '-box' : null;
                    return l;
                }))
            );
        },
        fetchPhotos() {
            return http
                .get(
                    ((url) => {
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'shooting_id',
                            this.shootingId
                        );
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'sort_item',
                            this.storageData.sortItem.value
                        );
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'sort_order',
                            this.storageData.sortOrder
                        );

                        url = monet.Maybe.fromNull(this.storageData.label)
                            .map((l) => urlUtil.addQueryParamIfDefined(url, 'label_id', l.id))
                            .orSome(url);

                        url = monet.Maybe.fromFalsy(this.storageData.pageNo) // 空白もnone
                            .map((n) => urlUtil.addQueryParamIfDefined(url, 'page_no', n))
                            .orSome(url);

                        this.storageData.selectedTags.forEach(
                            (t) =>
                                (url = urlUtil.addQueryParamIfDefined(url, 'tag_ids[]', t.id))
                        );

                        this.storageData.selectedFaces.forEach(
                            (f) =>
                                (url = urlUtil.addQueryParamIfDefined(url, 'face_ids[]', f))
                        );

                        return url;
                    })('photos')
                )
                .then((res) => {
                    this.photos = res.data.map((p) => {
                        p.uiStatus = {
                            selected: false,
                            selectedInPageSpread: false,
                            selectedInSwapCandidateSuggestion: false,
                        };
                        p.is_highlighted = false;

                        p.label_icon =
                            p.label.no >= 0 && p.label.no <= 9
                                ? 'mdi-numeric-' + p.label.no + '-box'
                                : null;

                        p.photo_faces = p.photo_faces.map((pf) => {
                            pf.is_highlighting = false; // 詳細ダイアログでの検出顔による矩形ハイライト用のフラグ
                            return pf;
                        });

                        return p;
                    });

                    this.shootingFaces = this.faces
                        .map((f) => {
                            f.is_highlighting = false;
                            return f;
                        })
                        .sort((f, s) => f.no - s.no);

                    // this.shootingFaces = this.photos
                    //     .flatMap(p => p.photo_faces.map(pf => pf.face))
                    //     .reduce((acc, f) => monet.Maybe.fromNull(acc.find(x => x.id === f.id)).cata(() => acc.concat(f), _ => acc), []) // 顔ID で一意にする。
                    //     .map(f => {
                    //         f.is_highlighting = false; // 検出人物による写真ハイライト用のフラグ
                    //         return f;
                    //     })
                    //     .sort((f, s) => f.no - s.no); // 顔マスタ番号の降順
                });
        },
        allFetchPhotos() {
            return http
                .get(
                    ((url) => {
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'shooting_id',
                            this.shootingId
                        );
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'sort_item',
                            this.storageData.sortItem.value
                        );
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'sort_order',
                            this.storageData.sortOrder
                        );

                        url = monet.Maybe.fromNull(this.storageData.label)
                            .map((l) => urlUtil.addQueryParamIfDefined(url, 'label_id', l.id))
                            .orSome(url);

                        url = monet.Maybe.fromFalsy(this.storageData.pageNo) // 空白もnone
                            .map((n) => urlUtil.addQueryParamIfDefined(url, 'page_no', n))
                            .orSome(url);

                        return url;
                    })('photos')
                )
                .then((res) => {
                    this.allPhotos = res.data.map((p) => {
                        p.uiStatus = {
                            selected: false,
                            selectedInPageSpread: false,
                            selectedInSwapCandidateSuggestion: false,
                        };
                        p.is_highlighted = false;

                        p.label_icon =
                            p.label.no >= 0 && p.label.no <= 9
                                ? 'mdi-numeric-' + p.label.no + '-box'
                                : null;

                        p.photo_faces = p.photo_faces.map((pf) => {
                            pf.is_highlighting = false; // 詳細ダイアログでの検出顔による矩形ハイライト用のフラグ
                            return pf;
                        });

                        return p;
                    });

                    this.shootingFaces = this.faces
                        .map((f) => {
                            f.is_highlighting = false;
                            return f;
                        })
                        .sort((f, s) => f.no - s.no);
                });
        },
        async searchPhotos() {
            try {
                await Promise.all([this.allFetchPhotos(), this.fetchPhotos()]);
                this.searchFrequencies();
            } catch (error) {
                if (error === 'unauthorized') return;
                this.showError('写真の検索に失敗しました。', '写真検索エラー', error);
            }
        },
        async selectedPhotos() {
            try {
                await Promise.all([this.allFetchPhotos(), this.fetchPhotos()]);
            } catch (error) {
                if (error === 'unauthorized') return;
                this.showError('写真の検索に失敗しました。', '写真検索エラー', error);
            }
        },
        // 撮影行事系
        initShootingStorageData() {
            this.storageData.shootingDialog = false;
            this.storageData.shootingModeUpdate = false;

            this.storageData.shooting.id = 0;
            this.storageData.shooting.name = '';
            this.storageData.shooting.date = undefined;
            this.storageData.shooting.photographerName = '';
            this.storageData.shooting.updatedAt = undefined;
        },
        createShooting() {
            return http.post('shootings/store', {
                album_id: this.albumId,
                name: this.storageData.shooting.name,
                date: this.storageData.shooting.date,
                photographer_name: this.storageData.shooting.photographerName,
            });
        },
        updateShooting() {
            return http.put('shootings/' + this.storageData.shooting.id, {
                name: this.storageData.shooting.name,
                date: this.$moment(this.storageData.shooting.date).format('YYYY-MM-DD'),
                photographer_name: this.storageData.shooting.photographer_name,
                note: this.storageData.shooting.note,
                updated_at: this.storageData.shooting.updated_at,
            });
        },
        editShooting(shooting) {
            http
                .get('shootings/' + shooting.id + '/edit')
                .then((res) => {
                    this.storageData.shooting.id = res.data.id;
                    this.storageData.shooting.name = res.data.name;
                    this.storageData.shooting.date = res.data.date;
                    this.storageData.shooting.photographerName =
                        res.data.photographer_name;
                    this.storageData.shooting.updatedAt = res.data.updated_at;

                    this.confirmLeaveReset(['storageData', 'shooting']);

                    this.storageData.shootingModeUpdate = true;
                    this.storageData.shootingDialog = true;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'データの取得に失敗しました。');
                });
        },
        async closeShootingDialog() {
            if ((await this.confirmLeave()) === false) return;

            this.storageData.shootingDialog = false;
            this.$refs.shootingForm.reset();

            setTimeout(() => {
                this.initShootingStorageData();
                this.confirmLeaveReset(['storageData', 'shooting']);
            }, 10);
        },
        changeThumbnailSize(change) {
            this.thumbnailSizeIndex = this.thumbnailSizeIndex + change;
        },
        selectAllPhotos(value) {
            const allPhotoTabSelected = this.allPhotoTabSelected;
            this.activePhotos.forEach((p) => {
                if (allPhotoTabSelected) {
                    p.uiStatus.selected = value;
                } else {
                    p.uiStatus.selectedInPageSpread = value;
                }
            });
            this.activeShouldSelectAll = !value;
        },
        selectAllPhotosInSwapSuggestion(value) {
            this.swapCandidatePhotos.forEach((p) => {
                p.uiStatus.selectedInSwapCandidateSuggestion = value;
            });
            this.shouldSelectAllInSwapSuggestion = !value;
        },
        deletePhotos() {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, '写真削除エラー'),
                    (photos) => {
                        this.showConfirm(
                            '選択した写真を削除します。　\nよろしいですか？'
                        ).then((confirmResult) => {
                            if (confirmResult) {
                                http
                                    .delete('photos/delete', {
                                        photo_ids: photos.map((p) => p.id).toArray(),
                                    })
                                    .then(() => {
                                        this.showInfo('選択写真の削除が完了しました。', '写真削除');
                                        this.searchPhotos();
                                    })
                                    .catch((error) => {
                                        if (error === 'unauthorized') return;
                                        this.showError(error, '写真削除エラー');
                                    });
                            }
                        });
                    }
                );
        },
        updateSelectionStatus(status, photo) {
            photo.is_selected_for_page_spread = status;
            http
                .backGroundRequest('put', 'photos/update_selection_status', {
                    photo_ids: [photo.id],
                    status: status,
                })
                .then(() => {
                    // 全写真タブがアクティブな時は変化が無いため更新しない
                    this.searchFrequencies();
                    this.undoMoveStack.push([
                        {
                            photo_ids: [photo.id],
                            status: !status,
                        },
                    ]);
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.searchPhotos();
                    this.showError(error, '選定状態更新エラー');
                });
        },
        updateAutoSelectionStatus(status, photo) {
            photo.is_auto_selected_for_page_spread = status;
            photo.is_selected_for_page_spread = true;
            http
                .backGroundRequest('put', 'photos/update_auto_selection_status', {
                    photo_ids: [photo.id],
                    status: status,
                })
                .then(() => {
                    console.log(photo);
                    this.searchFrequencies();
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.searchPhotos();
                    this.showError(error, '選定状態更新エラー');
                })
        },
        bulkChangePhotoSelectionStatusForPageSpread(status) {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, '写真選定状態変更エラー'),
                    (photos) => {
                        const photo_ids = photos
                            .filter((p) => p.is_selected_for_page_spread != status)
                            .map((p) => p.id)
                            .toArray();
                        if (photo_ids.length == 0) {
                            this.selectAllPhotos(false);
                            return;
                        }
                        http
                            .put('photos/update_selection_status', {
                                photo_ids: photo_ids,
                                status: status,
                            })
                            .then(() => {
                                this.undoMoveStack.push([
                                    {
                                        photo_ids: photo_ids,
                                        status: !status,
                                    },
                                ]);
                                this.searchPhotos();
                            })
                            .catch((error) => {
                                if (error === 'unauthorized') return;
                                this.showError(error, '写真選定状態変更エラー');
                            });
                    }
                );
        },
        undoChangeSelectionStatus() {
            monet.Maybe.fromNull(this.undoMoveStack.pop())
                .toValidation('元に戻せる操作がありません。')
                .cata(
                    (error) => this.showError(error, '写真選定状態変更エラー'),
                    (requests) => {
                        let url, payload;
                        if (requests.length == 1) {
                            url = 'photos/update_selection_status';
                            payload = {
                                photo_ids: requests[0].photo_ids,
                                status: requests[0].status,
                            };
                        } else if (requests.length == 2) {
                            const toBeSelected =
                                requests[0].status == true ? requests[0] : requests[1];
                            const toBeDisselected =
                                requests[0].status == false ? requests[0] : requests[1];

                            url = 'photos/swap';
                            payload = {
                                photo_ids_to_be_selected: toBeSelected.photo_ids,
                                photo_ids_to_be_disselected: toBeDisselected.photo_ids,
                            };
                        } else {
                            this.showError(
                                '写真選定操作取り消し時にエラーが発生しました。\n 以下のメッセージをシステム管理者にお知らせください。' +
                                ':写真選定操作履歴異常',
                                '写真選定操作取消エラー'
                            );
                        }
                        http
                            .put(url, payload)
                            .then(() => this.searchPhotos())
                            .catch((error) => {
                                if (error === 'unauthorized') return;
                                this.showError(error, '写真選定操作取消エラー');
                            });
                    }
                );
        },
        incrementPhotoLabel(photo, key) {
            const oldLabelNo = photo.label.no;
            const incrementedLabelId = key;
            // クリック連打で更新日時前後しないように更新中は消す
            photo.label_icon = undefined;

            http
                .backGroundRequest('put', 'photos/update/' + photo.id, {
                    name: photo.name,
                    label_id: incrementedLabelId,
                    photo_tags: photo.photo_tags.map((t) => t.tag.id),
                    page_no: photo.page_no,
                    is_selected_for_page_spread: photo.is_selected_for_page_spread,
                    updated_at: photo.updated_at,
                })
                .then((res) => {
                    photo.label = this.labels.find((label) => {
                        return label.id == res.data.label_id;
                    });
                    photo.label_icon =
                        photo.label.no >= 0 && photo.label.no <= 9
                            ? 'mdi-numeric-' + photo.label.no + '-box'
                            : null;
                    photo.updated_at = res.data.updated_at;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    // 失敗したら元に戻す
                    photo.label = this.labels.find((label) => {
                        return label.no == oldLabelNo;
                    });
                    photo.label_icon =
                        photo.label.no >= 0 && photo.label.no <= 9
                            ? 'mdi-numeric-' + photo.label.no + '-box'
                            : null;
                    this.showError(error, 'ラベル更新エラー');
                });
        },
        downloadPhotos() {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, '写真ダウンロードエラー'),
                    (photos) =>
                        http
                            .get(
                                ((url) => {
                                    url = urlUtil.addQueryParamIfDefined(
                                        url,
                                        'with_rectangle',
                                        this.storageData.withRectangle
                                    );
                                    url = urlUtil.addQueryParamIfDefined(
                                        url,
                                        'zip_file_name',
                                        this.storageData.shooting.name
                                    );
                                    photos.forEach(
                                        (p) =>
                                            (url = urlUtil.addQueryParamIfDefined(url, 'ids[]', p.id))
                                    );
                                    return url;
                                })('photos/export')
                            )
                            .then(async (res) => {
                                const preSignedUrl = res.data.url;
                                const jobId = res.data.job_id;

                                this.isDownloading = true;
                                const successCallback = () => { download(preSignedUrl, ''); };

                                awaitJobDone(jobId)
                                    .then(successCallback)
                                    .catch((error) => {
                                        this.showError(
                                            '写真のダウンロードに失敗しました。',
                                            '写真ダウンロードエラー',
                                            error
                                        );
                                    })
                                    .finally(() => {
                                        this.isDownloading = false;
                                    });

                            })
                            .catch((error) => {
                                if (error === 'unauthorized') return;
                                this.showError(
                                    '写真のダウンロードに失敗しました。',
                                    '写真ダウンロードエラー',
                                    error
                                );
                            })

                );
        },
        doOcrProc() {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, '札番号リネームエラー'),
                    (photos) =>
                        http
                            .put('photos/ai_ocr_rename', {
                                shooting_id: this.shootingId,
                                photo_ids: photos.map((p) => p.id).toArray(),
                            })
                            .then((res) => {
                                if (res.data.length == 0) {
                                    this.showInfo(
                                        '選択写真の札番号リネームが完了しました。',
                                        '札番号リネーム'
                                    );
                                } else {
                                    this.showError(
                                        '一部の写真の札番号読み取りに失敗しました。 \n選択状態の写真は札番号読み取りに失敗したため、リネームを行っていません。',
                                        '札番号リネームエラー'
                                    );
                                }
                                this.searchPhotos().then(() => {
                                    const allPhotoTabSelected = this.allPhotoTabSelected;
                                    this.activePhotos.forEach((p) => {
                                        if (res.data.includes(p.id)) {
                                            if (allPhotoTabSelected) {
                                                p.uiStatus.selected = true;
                                            } else {
                                                if (p.is_selected_for_page_spread) {
                                                    p.uiStatus.selectedInPageSpread = true;
                                                } else {
                                                    p.uiStatus.selectedInSwapCandidateSuggestion = true;
                                                }
                                            }
                                        }
                                    });
                                });
                            })
                            .catch((error) => {
                                if (error === 'unauthorized') return;
                                this.showError(error, '札番号リネームエラー');
                            })
                );
        },
        doAiProc() {
            monet.NEL.fromArray(this.activePhotos.filter((p) => p.selected))
                .toValidation('写真を選択してください。')
                .cata(
                    (error) => this.showError(error, 'AI処理エラー'),
                    (photos) => {
                        this.showConfirm(
                            '選択した写真にAI処理を行います。作成済みの検出顔情報は上書きされます。　\nよろしいですか？',
                            'AI処理'
                        ).then(() => {
                            http
                                .post('photo_faces/ai_proc', {
                                    album_id: this.album.id,
                                    photo_ids: photos.map((p) => p.id).toArray(),
                                    face_ids: [],
                                }) // 顔検出
                                .then((res) => {
                                    const jobId = res.data.job_id;

                                    awaitJobDone(jobId, true).then(() => {
                                        // 物体・シーン検出
                                        this.showInfo('選択写真のAI処理が完了しました。', 'AI処理');
                                        this.searchPhotos();
                                    }).catch((error) => {
                                        this.showError(error, 'AI処理エラー');
                                    });

                                })
                                .catch((error) => {
                                    if (error === 'unauthorized') return;
                                    this.showError(error, 'AI処理エラー');
                                });
                        });
                    }
                );
        },
        openPhotoDialog(photoId) {
            this.applyChangePhotoId(photoId);
        },
        applyChangePhotoId(photoId) {
            this.storageData.photoId = photoId;
            this.storageData.photoDialog = true;

            this.$nextTick(() => {
                this.$refs.photoDialogVue.initialize();
            });
        },
        applyUpdatePhoto(updatedPhotoId, maybeNextPhotoId) {
            Promise.all([this.fetchPhotos(), this.allFetchPhotos()])
                .then(() => {
                    this.storageData.photoId =
                        this.photos.length > 0
                            ? monet.Maybe.fromNull(
                                this.photos.find((p) => p.id === updatedPhotoId)
                            )
                                .catchMap(() =>
                                    maybeNextPhotoId.bind((nextPhotoId) =>
                                        monet.Maybe.fromNull(
                                            this.photos.find((p) => p.id === nextPhotoId)
                                        )
                                    )
                                )
                                .map((p) => p.id)
                                .orSome(this.photos[0].id) // 更新写真、その次の写真、先頭写真の順に探索。
                            : 0; // photos が空なら 0
                    this.$nextTick(() => {
                        this.$refs.photoDialogVue.initialize();
                    });
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(
                        '写真の再検索に失敗しました。',
                        '写真再検索エラー',
                        error
                    );
                });
        },
        closePhotoDialog() {
            this.storageData.photoDialog = false;
        },
        openShootingFrequencyDialog() {
            this.storageData.shootingFrequencyDialog = true;

            this.$nextTick(() =>
                this.$refs.shootingFrequencyVue
                    .initialize()
                    .then(() => {
                        this.$refs.shootingFrequencyVue.setShooting(this.shootingId);
                        this.$refs.shootingFrequencyVue.setActiveTab(this.selectedTab);
                    })
                    .catch((error) => {
                        this.closeShootingFrequencyDialog();

                        if (error === 'unauthorized') return;
                        this.showError(
                            '人物頻度確認画面の初期化に失敗しました。',
                            '初期化エラー',
                            error
                        );
                    })
            );
        },
        closeShootingFrequencyDialog() {
            this.storageData.shootingFrequencyDialog = false;
        },
        clickFace(faceId) {
            monet.Maybe.fromNull(
                this.shootingFaces.find((f) => f.id === faceId)
            ).forEach((f) => {
                f.is_highlighting = !f.is_highlighting; // トグル

                const highlightFaceIds = this.shootingFaces
                    .filter((f) => f.is_highlighting)
                    .map((f) => f.id);

                this.photos = this.photos.map((p) => {
                    p.is_highlighted = this.photo_faces.some((pf) =>
                        highlightFaceIds.includes(pf.face_id)
                    );
                    return p;
                }); // 写真のハイライト
            });
        },
        aggregateFrequency(photos) {
            return photos
                .flatMap((p) => p.photo_faces)
                .reduce(
                    (acc, pf) =>
                        monet.Maybe.fromNull(acc.find((x) => x[0].id === pf.face_id)).cata(
                            () => acc.concat([[pf.face, 1]]),
                            (x) =>
                                acc
                                    .filter((x) => x[0].id !== pf.face_id)
                                    .concat([[pf.face, x[1] + 1]])
                        ),
                    []
                ) // photos -> [[face, count]]
                .sort((f, s) => s[1] - f[1]); // 出現回数の降順にソート
        },
        searchFrequencies() {
            if (this.photos.length <= 0) return;

            this.frequencies = [];
            this.bestFrequencies = [];
            this.worstFrequencies = [];

            this.photos = this.photos.map((p) => {
                p.is_highlighted = false;
                return p;
            }); // 写真のハイライト解除

            this.frequencyDisplayLoading = true;
            this.fetchFrequencies()
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    console.log(error);
                    this.showError(
                        '人物頻度の検索に失敗しました。',
                        '人物頻度検索エラー',
                        error
                    );
                })
                .finally(() => {
                    this.frequencyDisplayLoading = false;
                });
        },
        fetchFrequencies() {
            return http
                .backGroundRequest('post', 'photo_faces/shooting_frequency', {
                    album_id: this.albumId,
                    photo_id_list: this.allPhotos.map((p) => {
                        return p.id;
                    }),
                    student_no: this.storageData.personStudentNo,
                    note: this.storageData.personNote,
                    only_selected: !this.allPhotoTabSelected,
                })
                .then((res) => {
                    const maxFrequencyOfWholeAlbum = Math.max.apply(
                        null,
                        res.data.map((f) => f.frequency_of_whole_album)
                    );
                    const maxFrequency = Math.max.apply(
                        null,
                        res.data.map((f) => f.student_frequency)
                    );

                    this.frequencies = res.data.map((f) => {
                        f.album_frequency_rate =
                            (f.frequency_of_whole_album / maxFrequencyOfWholeAlbum) * 100;
                        f.frequency_rate = (f.student_frequency / maxFrequency) * 100;
                        f.is_highlighting = false;
                        return f;
                    });

                    this.constractBothEndsOfFrequencies();

                    this.swapCandidatePhotoIds = [];
                });
        },
        constractBothEndsOfFrequencies() {
            const shouldSplit =
                this.frequencies.length > this.frequencyDisplayCount * 2;
            const sortedFrequencies = this.frequencies.sort((self, other) =>
                this.storageData.frequencySortItem.value === 1
                    ? (other.student_selected_frequency + other.student_auto_selected_frequency) - (self.student_selected_frequency + self.student_auto_selected_frequency)
                    : other.frequency_of_whole_album - self.frequency_of_whole_album
            );

            this.bestFrequencies = shouldSplit
                ? sortedFrequencies.slice(0, this.frequencyDisplayCount)
                : sortedFrequencies.slice(
                    0,
                    // 小数点切り捨て
                    this.frequencies.length / 2
                );

            this.worstFrequencies = shouldSplit
                ? sortedFrequencies.slice(-this.frequencyDisplayCount)
                : sortedFrequencies.slice(
                    // 小数点切り捨て
                    this.frequencies.length / 2,
                    this.frequencies.length
                );
        },
        searchSwapCandidatePhotos() {
            this.swapCandidatePhotoIds = [];

            const bests = this.bestFrequencies.filter((f) => f.is_highlighting);
            const worsts = this.worstFrequencies.filter((f) => f.is_highlighting);

            (bests.concat(worsts).length > 0 // 人物選択済なら Right、それ以外は Left とする。
                ? monet.Either.of([
                    bests.length > 0 ? 'remove' : 'add',
                    bests.concat(worsts),
                ])
                : monet.Left()
            ).forEach((t) =>
                this.fetchSwapCandidatePhotos(t[0], t[1]).catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(
                        '入替候補写真の検索に失敗しました。',
                        '入替候補写真検索エラー',
                        error
                    );
                })
            );
        },
        fetchSwapCandidatePhotos(purpose, faceIds) {
            this.swapCandidateLoading = true;
            return http
                .backGroundRequest(
                    'get',
                    ((url) => {
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'shooting_id',
                            this.shootingId
                        );
                        url = urlUtil.addQueryParamIfDefined(url, 'swap_purpose', purpose);
                        url = urlUtil.addQueryParamIfDefined(
                            url,
                            'candidate_num',
                            this.swapCandidateCount
                        );
                        faceIds.forEach(
                            (f) =>
                            (url = urlUtil.addQueryParamIfDefined(
                                url,
                                'face_id_list[]',
                                f.face_id
                            ))
                        );

                        return url;
                    })('photos/swap_candidate')
                )
                .then((res) => {
                    this.swapCandidatePhotoIds = res.data.map((p) => {
                        return p.id;
                    });
                    this.swapCandidatePhotos.forEach((photo) => {
                        photo.uiStatus.selectedInSwapCandidateSuggestion = false;
                    });
                })
                .finally(() => {
                    this.swapCandidateLoading = false;
                });
        },
        swapPhotos() {
            const url = 'photos/swap';
            const toBeSelected = this.swapCandidatePhotos
                .filter((photo) => {
                    return photo.uiStatus.selectedInSwapCandidateSuggestion;
                })
                .map((photo) => {
                    return photo.id;
                });
            const toBeDisselected = this.photosSelectedForPageSpread
                .filter((photo) => {
                    return photo.uiStatus.selectedInPageSpread;
                })
                .map((photo) => {
                    return photo.id;
                });

            http
                .put(url, {
                    photo_ids_to_be_selected: toBeSelected,
                    photo_ids_to_be_disselected: toBeDisselected,
                })
                .then(() => {
                    this.undoMoveStack.push([
                        {
                            photo_ids: toBeSelected,
                            status: false,
                        },
                        {
                            photo_ids: toBeDisselected,
                            status: true,
                        },
                    ]);
                    this.searchPhotos();
                    this.swapConfirmDialog = false;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '写真選定状態変更エラー');
                });
        },
        clickFaceInBestFrequencies(faceId) {
            if (this.storageData.selectedFaces.includes(faceId)) {
                this.storageData.selectedFaces = [];
                this.selectedPhotos();
            }
            else {
                this.storageData.selectedFaces = [faceId];
                this.selectedPhotos();
            }
        },
        clickFaceInWorstFrequencies(faceId) {
            this.highlightPhotos(faceId, false);
        },
        highlightPhotos(faceId, isBestSide) {
            const selfs = isBestSide ? this.bestFrequencies : this.worstFrequencies;
            const others = isBestSide ? this.worstFrequencies : this.bestFrequencies;

            monet.Maybe.fromNull(selfs.find((f) => f.face_id === faceId)).forEach(
                (f) => {
                    f.is_highlighting = !f.is_highlighting; // トグル

                    const highlightFaceIds = selfs
                        .filter((f) => f.is_highlighting)
                        .map((f) => f.face_id);

                    others.map((f) => {
                        f.is_highlighting = false;
                        return f;
                    });

                    this.photos = this.photos.map((p) => {
                        p.is_highlighted = p.photo_faces.some((pf) =>
                            highlightFaceIds.includes(pf.face_id)
                        );
                        return p;
                    }); // 写真のハイライト
                    this.searchSwapCandidatePhotos();
                }
            );
        },
        switchWithRectangleMode() {
            this.storageData.withRectangle = !this.storageData.withRectangle;
        },
        openPhotoUploadDialog() {
            this.storageData.photoUploadDialog = true;
        },
        closePhotoUploadDialog() {
            this.storageData.photoUploadDialog = false;
        },
        openFaceSearchDialog() {
            this.storageData.faceSearchDialog = true;
        },
        closeFaceSearchDialog() {
            this.storageData.faceSearchDialog = false;
        },
        openAutoSelectDialog() {
            this.$refs.autoSelectDialogVue.display = true;

            this.$nextTick(() => {
                this.$refs.autoSelectDialogVue.initialize();
            });
        },
        closeAutoSelectedPhotosDialog() {
            this.storageData.autoSelectedPhotosDialog = false;
            this.searchPhotos();
        },
        autoSelectCompleted(result) {
            this.$refs.autoSelectDialogVue.display = false;
            this.searchPhotos();
        },
        registerPageSpreadMemo() {
            this.updateShooting()
                .then(() => {
                    this.fetchShooting();
                    this.storageData.pageSpreadMemoDialog = false;
                })
                .catch((error) => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'メモ登録エラー');
                });
        },
        transitToShootings() {
            this.$emit('change-active-component', 'shootingsVue');
        },
        selectedFrequentFaces(faces) {
            this.storageData.selectedFaces = faces.map(face => face.face_id);
            this.selectedPhotos();
        }
    },
};

const download = (href, filename) => {
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    link.click();
};
</script>


<style scoped>
.no-underline-breadcrumbs>>>a:not(:hover) {
    text-decoration: none !important;
}

.icon-medium {
    min-width: 40px
}

.hover-pointer:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
}
</style>

<style>
.v-tabs-bar {
    height: 60px !important;
    padding-right: 28px;
}

.custom-select {
    height: 25px !important;
    width: 86px !important;
}

.custom-select .v-label {
    font-size: 9px !important;
    top: 0 !important;
    margin-top: 4px !important;
}

.custom-select .v-input__control {
    height: 25px !important;
    width: 86px !important;
}

.custom-select .v-input__slot {
    min-height: 25px !important;
    height: 25px !important;
    width: 86px !important;
    padding: 0 8px !important;
}

.custom-select .v-select__selections {
    width: 86px !important;
    height: 25px !important;
    font-size: 9px !important;
    padding: 0 !important;
    display: flex;
    align-items: flex-start;
    /* 文字サイズを9pxに変更 */
}

.custom-select .v-select__label {
    height: 25px !important;
    width: 86px !important;
    font-size: 9px !important;
    /* ラベルの文字サイズも9pxに */
}

.custom-select .v-select__slot {
    height: 25px !important;
    width: 86px !important;
    font-size: 9px !important;
    /* ラベルの文字サイズも9pxに */
}

.custom-select .v-input__append-inner {
    margin-top: 0 !important;
}

.custom-select-label {
    height: 25px !important;
    width: 120px !important;
}

.custom-select-label .v-label {
    font-size: 9px !important;
    top: 0 !important;
    margin-top: 4px !important;
}

.custom-select-label .v-input__control {
    height: 25px !important;
    width: 120px !important;
}

.custom-select-label .v-input__slot {
    min-height: 25px !important;
    height: 25px !important;
    width: 120px !important;
    padding: 0 8px !important;
}

.custom-select-label .v-select__selections {
    width: 120px !important;
    height: 25px !important;
    font-size: 9px !important;
    padding: 0 !important;
    display: flex;
    align-items: flex-start;
    /* 文字サイズを9pxに変更 */
}

.custom-select-label .v-select__label {
    height: 25px !important;
    width: 120px !important;
    font-size: 9px !important;
    /* ラベルの文字サイズも9pxに */
}

.custom-select-label .v-select__slot {
    height: 25px !important;
    width: 120px !important;
    font-size: 9px !important;
    /* ラベルの文字サイズも9pxに */
}

.custom-select-label .v-input__append-inner {
    margin-top: 0 !important;
}

.custom-select-label .mdi-close {
    font-size: 16px !important;
}

.search-button {
    width: 100px;
    height: 35px;
    padding: 0 !important;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shooting-header>div {
    height: 48px;
}
</style>
<style>
.v-main__wrap {
    overflow: ovarlay hidden;
    height: 120vh;
}

.v-slide-group__prev,
.v-slide-group__next {
    display: none !important;
}
</style>