<template>
    <v-lazy v-model="lazy" :options="{ rootMargin: '250px', threshold: 0.5 }" min-height="160">
        <v-card class="photo-thumbnail-card"
            :style="{ 'border': isSelected ? '2px solid red' : '', 'padding': isSelected ? '0' : '2px 0' }"
            style="border-radius: 0; padding: 1px 0;" :min-width="size.width" :max-width="size.width"
            @mouseover="enableInput" @mouseleave="disableInput" :class="photo.is_highlighted ? 'highlighted' : ''">
            <v-container class="ma-0 pa-0">
                <v-row style="margin: 0 4px;" dense justify="space-between">
                    <v-col cols="4" class="ml-0 pl-0 d-flex align-center">
                        <v-checkbox v-model="_emitter_isSelected" style="width: 24px;" dense hide-details
                            class="ma-0 ml-1 pa-0" />
                        <img v-if="photo.is_indexed" style="width: 18px; height: 18px; margin-bottom: 4px;"
                            src="/images/check-face.png" />
                    </v-col>
                    <v-col cols="4" style="display: flex; padding-right: 4px;" class="mr-0 pr-0 text-right">
                        <v-select v-model="photo.label" :items="labels" item-text="id" return-object dense hide-details
                            @change="updatelabel" class="custom-update-label">
                            <template #item="{ item }">
                                <v-icon v-if="!!item.label_icon" :color="'#' + item.color_code">
                                    {{ item.label_icon }}
                                </v-icon>
                                <img v-else style="height: 14px; width: 51px;" src="/images/delete-label.png" />
                            </template>
                            <template #selection="{ item }">
                                <v-icon v-if="!!item.label_icon" :color="'#' + item.color_code">
                                    {{ item.label_icon }}
                                </v-icon>
                                <img v-else style="height: 19px; width: 70px;" src="/images/delete-label.png" />
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row v-if="multipleTags" dense justify="space-between">
                    <v-col class="ml-2 pl-0">
                        <span>
                            <v-chip v-for="pt in photo.photo_tags" :key="pt.id" :color="pt.tag.back_color_code"
                                :text-color="pt.tag.fore_color_code" small class="mr-1"
                                :style="{ verticalAlign: '-webkit-baseline-middle' }">
                                {{ pt.tag.name.slice(0, 1) + '.' }}
                            </v-chip>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-img :src="thumbnailFilePathWithQueryParam" contain :max-width="size.width"
                            :max-height="size.height" :class="photo.is_highlighted ? 'highlighted' : ''"
                            @click="clickCallback(photo.id)" style="cursor: pointer;" />
                    </v-col>
                </v-row>
                <v-row v-if="!!photo.page_no" dense>
                    <v-col cols="2">
                        {{ 'P' + photo.page_no }}
                    </v-col>
                    <v-col cols="10" class="photo-name">
                        {{ photo.name }}
                    </v-col>
                </v-row>
                <v-row v-else dense style="text-align: center;">
                    <v-col cols="12" class="photo-name">
                        {{ photo.name }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" style="display: flex; justify-content: center;">
                        <v-btn v-if="photo.is_selected_for_page_spread" small outlined color="#FB782D"
                            @click="() => { _emitter_is_selected_for_page_spread = false }"
                            class="select-button d-flex align-center" style="padding-left: 10px;">
                            <img class="mr-2" style="width: 16px; height: 16px;" src="/images/lets-icons_check-fill.png" />
                            <span style="flex-grow: 1; text-align: center;">選定中</span>
                        </v-btn>
                        <v-btn v-else-if="photo.is_auto_selected_for_page_spread" small outlined color="#0F68B1"
                            @click="() => { _emitter_is_auto_selected_for_page_spread = false }"
                            class="select-button d-flex align-center" style="padding-left: 10px;">
                            <img class="mr-2" style="width: 16px; height: 16px;"
                                src="/images/lets-icons_check-fill-auto.png" />

                            <div style="flex-grow: 1; text-align: center;">
                                <img style="width: 10px; height: 10px;" src="/images/tabler_photo-ai-blue.png" />
                                <span>
                                    選定中
                                </span>
                            </div>
                        </v-btn>
                        <v-btn v-else small outlined @click="() => { _emitter_is_selected_for_page_spread = true }"
                            class="select-button" style="background-color: #FB782D; color: #fff;">
                            選定する
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-lazy>
</template>

<script>
import monet from 'monet';
import http from '../../services/http';

export default {
    props: {
        photo: {
            default: undefined,
        },
        labels: {
            default: () => [],
            type: Array[Object],
        },
        size: {
            default: () => {
                return { width: 240, height: 160 };
            },
        },
        withRectangle: {
            default: false,
        },
        isSelected: {
            default: false,
            type: Boolean,
        },
        clickCallback: {
            default: x => { },
            type: Function,
        },
    },
    data() {
        return {
            lazy: false,
            isInputEnabled: false,
            isProcessing: false,
            eventKey: 0,
            showLabelMenu: false,
        };
    },
    computed: {
        thumbnailFilePathWithQueryParam() {
            return this.withRectangle
                ? monet.Maybe.fromFalsy(this.photo.visualized_file_path)
                    .map(v => v += '?' + Math.random()).orSome(this.photo.thumbnail_file_path)
                : this.photo.thumbnail_file_path;
        },
        multipleTags() {
            return this.photo.photo_tags.length > 1;
        },
        _emitter_isSelected: {
            get: function () {
                return this.isSelected;
            },
            set: function (value) {
                this.$emit('photo-selected', value);
            },
        },
        _emitter_is_selected_for_page_spread: {
            get: function () {
                return this.photo.is_selected_for_page_spread;
            },
            set: function (value) {
                this.$emit('selection-status-changed-for-page-spread', value);
            },
        },
        _emitter_is_auto_selected_for_page_spread: {
            get: function () {
                return this.photo.is_auto_selected_for_page_spread;
            },
            set: function (value) {
                this.$emit('auto-selection-status-changed-for-page-spread', value);
            },
        }
    },
    methods: {
        enableInput() {
            this.isInputEnabled = true;
            window.addEventListener('keydown', this.handleKeydown); // グローバルにリッスン
        },
        disableInput() {
            this.isInputEnabled = false;
            window.removeEventListener('keydown', this.handleKeydown);
        },
        handleKeydown(event) {
            if (this.isProcessing) {
                // 既に処理中なら何もしない
                return;
            }
            if (this.isInputEnabled && event.key >= '0' && event.key <= '9') {
                // 入力値を更新
                let currentNumber = parseInt(event.key, 10); // event.keyは文字列なので、parseIntで数値に変換
                currentNumber += 1;
                this.eventKey = currentNumber.toString();
            } else if (event.key === 'Delete' || event.key === 'x') {
                // Deleteキーを処理
                this.eventKey = '11';
            }
            this.$emit('label-changed', this.photo, this.eventKey);

            // 処理中フラグを立てる
            this.isProcessing = true;

            // 一定時間後に解除（連続処理防止）
            setTimeout(() => {
                this.isProcessing = false;
            }, 500); // 500msの間は再度変更できない
        },
        updatelabel() {
            this.$emit('label-changed', this.photo, this.photo.label.id);
        },
        // not using
        updateSelectionStatus(status) {
            http
                .backGroundRequest('put', 'photos/update_selection_status', {
                    photo_ids: [this.photo.id],
                    status: status,
                })
                .then(
                    this.$emit('selection-status-updated')
                )
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '選定状態更新エラー');
                });
        },
    },
};

</script>

<style>
.photo-thumbnail-card div.photo-name {
    text-align: center;
}

.photo-thumbnail-card.v-card.highlighted {
    border: solid 2px #FFFFFF;
}

.select-button {
    width: 95%;
    margin: 0 4px;
    border-radius: 0;
}

.custom-update-label div.v-select__selections {
    flex-direction: row-reverse;
    /* width: 25px !important;
    height: 25px !important; */
}

.v-select .v-input__control .v-input__slot:before,
.v-select .v-input__control .v-input__slot:after {
    content: none !important;
    /* 擬似要素の内容を削除 */
    border-bottom: none !important;
    /* 下線を削除 */
    width: 0 !important;
    /* 下線部分の幅をゼロに */
}

/* .custom-update-label .v-select__label {
    height: 25px !important;
    width: 25px !important;
}

.custom-update-label .v-select__slot {
    height: 25px !important;
    width: 25px !important;
} */

.custom-update-label div.v-input__append-inner {
    display: none !important;
}
</style>
