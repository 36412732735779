<template>
    <v-card class="person-frequency-card" style="margin: 4px;">
        <v-container style="display: flex; flex-direction: column; align-items: center; padding: 4px; width: 100%;">
            <!-- アルバム全体頻度表示部 -->
            <v-row style="width: 100%; height: 16px;" justify="space-between">
                <v-col cols="6" class="ma-0 pa-0">
                    <div style="height: 20px; display: flex; align-items: center;">
                        <img style="height: 11px; width: 11px; margin-right: 2px;" src="/images/ri_camera-fill.png" />
                        <span style="font-size: 10px !important;">
                            {{ frequency.shooting_student_frequency }}
                        </span>
                    </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0" style="display: flex; justify-content: flex-end;">
                    <div style="height: 20px; display: flex; align-items: center;">
                        <img style="height: 9px; width: 10px; margin-right: 2px;" src="/images/Vector.png" />
                        <span style="color: #FC81A4; font-size: 10px !important;">
                            {{ frequency.frequency_of_whole_album }}
                        </span>
                    </div>
                </v-col>
            </v-row>
            <!-- 顔画像表示部 -->
            <v-row class="ma-0 pa-0" style="width: 100%;">
                <v-col v-if="!rerendering" class="text-center pa-1">
                    <v-img v-if="!rerendering" :src="frequency.face_image_path" contain height="80" max-width="95"
                        style="cursor: pointer;" :class="isUnselectedFace ? 'unselectedFace' : ''"
                        @click="clickFaceImage" />
                    <span style="font-size: 7px;">No.</span>
                    <span style="font-size: 8px;">{{ frequency.no }}</span>
                </v-col>
            </v-row>
            <!-- 撮影行事内頻度表示部 -->
            <v-row class="ma-0 pa-0" style="width: 100%;">
                <v-col cols="6" class="ma-0 pa-0">
                    <div
                        style="border: 2px solid #FF8B00; border-radius: 8px; background-color: #FF8B00; width: 27px; height: 13px; display: flex; justify-content: center; align-items: center;">
                        <img style="height: 7px; width: 9px; margin-right: 2px;" src="/images/ooui_hand.png" />
                        <span style="color: #FFFFFF; font-size: 10px;">
                            {{ frequency.student_selected_frequency }}
                        </span>
                    </div>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0" style="display: flex; justify-content: flex-end;">
                    <div v-if="frequency.student_auto_selected_frequency != 0"
                        style="border: 2px solid #0F68B1; border-radius: 8px; background-color: #0F68B1; width: 27px; height: 13px; display: flex; justify-content: center; align-items: center;">
                        <span style="color: #FFFFFF; font-size: 10px;">+</span>
                        <span style="color: #FFFFFF; font-size: 10px;">
                            {{ frequency.student_auto_selected_frequency }}
                        </span>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    props: {
        frequency: {
            type: Object,
            default: () => { },
        },
        isUnselectedFace: {
            type: Boolean,
            default: false,
        },
        clickCallback: {
            type: Function,
            default: () => { },
        },
    },
    data() {
        return {
            rerendering: false,
        };
    },
    methods: {
        clickFaceImage() {
            this.clickCallback(this.frequency.face_id);
            this.rerender(); // 強制的に再描画
        },
        rerender() {
            this.rerendering = true;
            this.$nextTick(() => this.rerendering = false);
        },
    },
};
</script>

<style>
div.v-image.unselectedFace {
    filter: grayscale(1);
}
</style>
