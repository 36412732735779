<template>
    <v-dialog v-model="display" max-width="480px" scrollable>
        <v-card>
            <v-card-title class="text-md-subtitle-1" style="color: #FFF; background-color: #0F68B1; font-size: 12px;">
                自動選定の条件設定
            </v-card-title>
            <v-card-text class="pa-4 hide-overlay">
                <div style="margin-bottom: 16px;">手動選定済みと合わせて、以下の合計枚数になるように自動選定します。</div>
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <span style="margin: 0 16px !important;">合計枚数</span>
                    <v-text-field v-model="selectedLength" :rules="rules.expectPhotoAmountRules" type="number" min="1"
                        max="999" class="ma-0 pa-0 text-field"
                        style="max-width: 80px; display: flex; align-items: center;" />
                </div>
                <div style="border: 1px solid #666666; margin-bottom: 16px; padding: 16px; border-radius: 4px;">
                    <span style="font-weight: bold;">優先する写真<br></span>
                    <span>数字ラベルが以下の写真を必ず選ぶ<br></span>
                    <template v-for="(label, index) in labelsWithActive" :keys="lables">
                        <v-icon v-if="index != 0" :color="'#' + label.color_code"
                            :style="{ margin: '8px', opacity: label.isActive ? 1 : 0.2 }" @click="toggleFlag(index)">
                            {{ label.label_icon }}
                        </v-icon>
                    </template>
                </div>
                <div style="border: 1px solid #666666; margin-bottom: 16px; padding: 16px; border-radius: 4px;">
                    <span style="font-weight: bold;">選ばない写真</span>
                    <div style="display: flex; align-items: center;">
                        <v-checkbox v-model="deleteLabel" class="ma-0 pa-0 checkbox" />
                        <img style="height: 14px; width: 51px; margin-right: 8px;" src="/images/delete-label.png" />
                        <span>が付いた写真は選ばない</span>
                    </div>
                </div>
                <div style="border: 1px solid #666666; margin-bottom: 16px; padding: 16px; border-radius: 4px;">
                    <span style="font-weight: bold;">重視する条件(最適化目標)</span>
                    <span v-if="conditionsError" style="color: #ff8086; margin-left: 8px;">重視する条件を選択してください</span>
                    <div style="display: flex; align-items: center; margin: 4px 0;">
                        <v-checkbox v-model="zeroPeople" class="ma-0 pa-0 checkbox" />
                        <span>頻度が0回の人物をなるべく無くす</span>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <v-checkbox v-model="maxMinFrequency" class="ma-0 pa-0 checkbox" />
                        <span>頻度が最多・最小の差をなるべく小さくする</span>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="px-4">
                <v-container class="px-3">
                    <v-row justify="space-between">
                        <v-btn class="button" style="background-color: #FFFFFF; border: 1px solid #666666;"
                            @click="closeAutoSelectDialog">
                            キャンセル
                        </v-btn>
                        <v-btn class="button" style="background-color: #0F68B1; color: #FFF;" @click="autoSelectPhoto">
                            <img style="height: 16px; width: 16px; margin-right: 8px;" src="/images/tabler_photo-ai.png" />
                            実行開始
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
import { awaitJobDone } from '../../services/awaitJobDone';

export default {
    props: {
        albumId: {
            type: [Number, String],
            default: 0,
        },
        shootingId: {
            type: [Number, String],
            default: 0,
        },
        labels: {
            type: Array,
            required: true,
        },
        completedCallback: {
            type: Function,
            default: () => { },
        },
    },
    data() {
        return {
            display: false,
            deleteLabel: false,
            zeroPeople: true,
            selectedLength: 0,
            conditionsError: false,
            maxMinFrequency: true,
            labelsWithActive: this.labels.map(label => ({
                ...label,
                isActive: false  // isActiveを追加
            })),
            rules: {
                expectPhotoAmountRules: [
                    v => !!v || '入力してください。',
                    v => !v || v && Math.round(v) == v || '整数値を入力してください。',
                    v => !v || v && (v >= 1 && v <= 999) || '1～999の範囲で入力してください。',
                ],
            },
        };
    },
    methods: {
        autoSelectPhoto() {
            if (!this.zeroPeople && !this.maxMinFrequency) {
                this.conditionsError = true;
                return;
            }
            else {
                this.conditionsError = false;
            }
            if (!this.rules.expectPhotoAmountRules.every(rule => rule(this.selectedLength) == true)) {
                return;
            }
            //遺伝的アルゴリズムの処理
            const uri = '/api/algorithm';

            // labelsのisActiveフラグ、checkboxの結果

            return axios
                .post(uri, {
                    label: this.labelsWithActive,
                    numElements: this.selectedLength,
                    deleteLabel: this.deleteLabel,
                    zeroPeople: this.zeroPeople,
                    maxMinFrequency: this.maxMinFrequency,
                    albumId: this.albumId,
                    shootingId: this.shootingId
                })
                .then((res) => {
                    const jobId = res.data.job_id;

                    awaitJobDone(jobId, true).then((jobResult) => {
                        this.showInfo('自動選定が完了しました。', '自動選定');
                        this.completedCallback(jobResult);
                    }).catch(error => {
                        this.showError(error, '自動選定エラー');
                    });
                    return;
                })
                .catch((e) => {
                    console.log('error');
                    console.log(e);
                    throw e;
                });
        },
        toggleFlag(index) {
            this.labelsWithActive[index].isActive = !this.labelsWithActive[index].isActive;
        },
        closeAutoSelectDialog() {
            this.display = false;
        },
    },
    computed: {
        labelsWithActive() {
            return this.labels ? this.labels.map(label => ({
                ...label,
                isActive: false  // isActiveを追加
            })) : [];
        }
    }
};
</script>
<style>
.text-field .v-input__control {
    display: contents;
}

.text-field input {
    text-align: center;
    padding: 0 0 0 16px !important;
    min-width: 79px;
}

.v-text-field .v-text-field__details {
    max-width: none !important;
}

.v-text-field .v-messages {
    margin-left: 8px;
    white-space: nowrap;
}

.checkbox .v-input__control {
    display: contents;
}

.checkbox .v-input__slot {
    margin-bottom: 0;
}

.button {
    width: 150px;
    height: 35px;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>